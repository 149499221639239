import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ShellListing from "../../modules/pages/shell-listing";
import {
  GetShellListing,
  TestPreListingCronLogic,
  fakeActionShellListing,
} from "../../redux/modules/shell-listing/index.action";
const mapStateToProps = (state) => ({
  GetShellListingResponse: state.ShellListing.GetShellListingResponse,
  TestPreListingCronLogicResponse:
    state.ShellListing.TestPreListingCronLogicResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetShellListing,
      TestPreListingCronLogic,
      fakeActionShellListing,
    },
    dispatch
  );

const ShellListing_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShellListing);

export default ShellListing_;
