import { message, Pagination, Table } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

const ShellListing = (props) => {
  const { GetShellListing, TestPreListingCronLogic, fakeActionShellListing } =
    props;

  const GetShellListingRes = useSelector(
    (state) => state.ShellListing.GetShellListingResponse || {}
  );
  const TestPreListingCronLogicRes = useSelector(
    (state) => state.ShellListing.TestPreListingCronLogicResponse || {}
  );
  const [loading, setLoading] = useState(true);

  const [list, setList] = useState([]);
  const [current, setCurrent] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(1);

  const [step1Loading, setStep1Loading] = useState(false);
  const [step2Loading, setStep2Loading] = useState(false);

  useEffect(() => {
    GetShellListing({
      page: current,
      perPage: rowsPerPage,
    });
  }, []);

  useEffect(() => {
    if (GetShellListingRes?.status === true) {
      setList(GetShellListingRes?.data?.records || []);
      setLoading(false);
      let pagination = GetShellListingRes?.data?.pagination;
      setTotalRecord(pagination?.totalCount);
      fakeActionShellListing();
    } else if (GetShellListingRes?.status === false) {
      setLoading(false);
      fakeActionShellListing();
    }
  }, [GetShellListingRes]);

  useEffect(() => {
    if (TestPreListingCronLogicRes?.status === true) {
      setStep1Loading(false);
      setStep2Loading(false);
      message.destroy();
      message.success(TestPreListingCronLogicRes?.message);
      fakeActionShellListing();
    } else if (TestPreListingCronLogicRes?.status === false) {
      setStep1Loading(false);
      setStep2Loading(false);
      fakeActionShellListing();
    }
  }, [TestPreListingCronLogicRes]);

  const columns = [
    {
      title: "File",
      dataIndex: "file_name",
      key: "file_name",
      width: 150,
      render: (file_name) => <span className="fw-bold">{file_name}</span>,
    },
    {
      title: "Client Account ID",
      dataIndex: "client_account_id",
      key: "client_account_id",
      width: 200,
    },
    {
      title: "Feed Document ID",
      dataIndex: "feed_document_id",
      key: "feed_document_id",
      width: 400,
    },
    {
      title: "Import Time",
      dataIndex: "importTime",
      key: "importTime",
      width: 200,
    },
    {
      title: "Feed Status",
      dataIndex: "feed_status",
      key: "feed_status",
      width: 200,
      render: (status) => (
        <span
          className="fw-bold"
          style={{ color: status === "DONE" ? "green" : "orange" }}
        >
          {status}
        </span>
      ), // Optional color for status
    },
    {
      title: "Amazon Feed File Data",
      dataIndex: "feed_data",
      key: "feed_data",
      width: 300,
      render: (feed_data) => {
        return feed_data ? (
          <label
            className="fw-semibold cursor-pointer"
            style={{ color: "#6969cd" }}
          >
            Download
          </label>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Feed Result",
      dataIndex: "feed_result",
      key: "feed_result",
      width: 200,
      render: (feed_data) => {
        return feed_data ? (
          <label
            className="fw-semibold cursor-pointer"
            style={{ color: "#6969cd" }}
          >
            Download
          </label>
        ) : (
          "-"
        );
      },
    },
  ];

  const onShowSizeChange = (current, pageSize) => {
    setCurrent(current);
    setRowsPerPage(pageSize);
    setLoading(true);
    GetShellListing({
      page: current,
      perPage: pageSize,
    });
  };

  const onPaginationChange = (page, pageSize) => {
    if (current !== page) {
      setCurrent(page);
      setLoading(true);
      GetShellListing({
        page: page,
        perPage: pageSize,
      });
    }
  };

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="row">
            <div className="col-12 col-md-5 my-2">
              <div className="card " style={{ height: "100%" }}>
                <div className="card-header  border-bottom-dashed">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">
                      Step 1: FBA Listing (For Testing)
                    </span>
                  </h3>
                  <div className="card-toolbar">
                    {/* <button className="btn btn-primary btn-icon">
                      <i class="ki-outline ki-menu fs-1" />
                    </button> */}
                  </div>
                </div>
                <div className="card-body py-5">
                  <div className="row">
                    <div className="col-12">
                      <label className="text-gray-700 fw-semibold fs-5">
                        Get QuickBase Order and Submit Data to AMAZON
                      </label>
                    </div>
                    <div className="col-12 mt-5">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          setStep1Loading(true);
                          TestPreListingCronLogic(1);
                        }}
                        disabled={step1Loading}
                      >
                        {" "}
                        {step1Loading && (
                          <span className="spinner-border spinner-border-sm align-middle me-2" />
                        )}{" "}
                        Click Here to Test
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-7 my-2">
              <div className="card " style={{ height: "100%" }}>
                <div className="card-header  border-bottom-dashed">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">
                      Step 2: FBA Listing (For Testing) (After 30 min of Step 1)
                    </span>
                  </h3>
                  <div className="card-toolbar">
                    {/* <button className="btn btn-primary btn-icon">
                      <i class="ki-outline ki-menu fs-1" />
                    </button> */}
                  </div>
                </div>
                <div className="card-body py-5">
                  <div className="row">
                    <div className="col-12">
                      <label className="text-gray-700 fw-semibold fs-5">
                        Create Amazon listing on QuickBase
                      </label>
                    </div>
                    <div className="col-12 mt-5">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          setStep2Loading(true);
                          TestPreListingCronLogic(2);
                        }}
                        disabled={step2Loading}
                      >
                        {" "}
                        {step2Loading && (
                          <span className="spinner-border spinner-border-sm align-middle me-2" />
                        )}{" "}
                        Click Here to Test
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-5 g-xl-5 mb-5">
            <div className="col-md-12 d-md-flex justify-content-sm-between">
              <div className="d-md-flex justify-content-start">
                <div className="fs-5 fw-bold text-gray-700 d-flex align-items-center ps-3 pt-5">
                  Showing{" "}
                  {totalRecord === 0
                    ? 0
                    : current === 1
                    ? 1
                    : (current - 1) * rowsPerPage + 1}{" "}
                  to{" "}
                  {totalRecord <= rowsPerPage
                    ? totalRecord
                    : totalRecord < rowsPerPage * current
                    ? totalRecord
                    : rowsPerPage * current}{" "}
                  of {totalRecord} entries
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12 my-2">
              <div className="card " style={{ height: "100%" }}>
                <div className="card-header  border-bottom-dashed">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">
                      Shell Uploaded Feed List
                    </span>
                  </h3>
                  <div className="card-toolbar">
                    <button className="btn btn-primary btn-sm">
                      Pre Listing ASIN Status
                    </button>
                  </div>
                </div>
                <div className="card-body py-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive">
                        <Table
                          columns={columns}
                          dataSource={list}
                          pagination={false}
                          scroll={{ x: 1500 }}
                          //   bordered
                          //   pagination={{ pageSize: 5 }}
                        />
                      </div>
                      {totalRecord > 10 && (
                        <div className="d-flex flex-stack flex-wrap pt-0 mt-5">
                          <div />
                          <Pagination
                            showSizeChanger
                            onShowSizeChange={onShowSizeChange}
                            current={current}
                            total={totalRecord}
                            onChange={onPaginationChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShellListing;
