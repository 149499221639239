import { SaveInventoryItemsWithoutOrder } from "./index.action";

export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_SHELL_LISTING_SUCCESS":
    case "GET_SHELL_LISTING_ERROR":
      return {
        ...state,
        GetShellListingResponse: action.updatePayload,
      };
    case "TEST_PRE_LISTING_CRON_LOGIC_SUCCESS":
    case "TEST_PRE_LISTING_CRON_LOGIC_ERROR":
      return {
        ...state,
        TestPreListingCronLogicResponse: action.updatePayload,
      };

    case "FAKE_ACTION_SHELL_LISTING":
      return [];
    default:
      return state;
  }
};
