import axiosCall from "../../configurations/network-services/axiosCall";

export const GetShellListing = (data) => {
  const path = `pre-listings/pre-listing-feeds?page=${data?.page}&per-page=${data?.perPage}`;
  const responseType = "GET_SHELL_LISTING";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const TestPreListingCronLogic = (step) => {
  const path = `pre-listings/test-pre-listing?step=${step}`;
  const responseType = "TEST_PRE_LISTING_CRON_LOGIC";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionShellListing = () => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_SHELL_LISTING" });
};
